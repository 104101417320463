import { gql, useApolloClient } from '@apollo/client';
import {
    AddFavoriteActivityDocument,
    AddFavoriteActivityInput,
    AddFavoriteCompanyDocument,
    AddFavoriteCompanyInput,
    AddFavoriteRentalUnitDocument,
    AddFavoriteRentalUnitInput,
    AddFavoriteRentalUnitMutation,
    FavoriteButtonDocument,
    FavoriteButtonQuery,
    useFavoriteButtonQuery,
    useRemoveFavoriteMutation,
} from '../generated/graphql';
import React, { ComponentType, FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Heart from './svg/Heart.svg';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import withAuth from '../hoc/withAuth';
import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import LoginContent from './LoginContent';
import { useTenantContext } from '../context/TenantContext';
import { useMutation } from '../apollo';
import { DocumentNode } from 'graphql';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import { useRouter } from 'next/router';
import { parse } from 'query-string';
import omit from 'lodash.omit';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import { toast } from 'react-toastify';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import RegisterContent from './RegisterContent';

interface ButtonProps extends Pick<HTMLAttributes<HTMLButtonElement>, 'onClick' | 'style'> {
    disabled?: boolean;
    submitting?: boolean;
}

export interface FavoriteButtonProps {
    type: 'rentalUnit' | 'activity' | 'company';
    id: string;
    isAuthorized?: boolean;
    ActiveButtonComponent?: ComponentType<React.PropsWithChildren<ButtonProps>>;
    InactiveButtonComponent?: ComponentType<React.PropsWithChildren<ButtonProps>>;
    landingUrl?: string;
    Spinner?: ComponentType<React.PropsWithChildren<unknown>>;
}

export const query = gql`
    query FavoriteButton {
        viewer {
            favorites {
                __typename
                id
                targetId
            }
        }
    }
`;

export const mutation = gql`
    mutation addFavoriteActivity($input: AddFavoriteActivityInput!) {
        result: addFavoriteActivity(input: $input) {
            favorite {
                ...MyFavorites
            }
        }
    }
    mutation addFavoriteCompany($input: AddFavoriteCompanyInput!) {
        result: addFavoriteCompany(input: $input) {
            favorite {
                ...MyFavorites
            }
        }
    }
    mutation addFavoriteRentalUnit($input: AddFavoriteRentalUnitInput!) {
        result: addFavoriteRentalUnit(input: $input) {
            favorite {
                ...MyFavorites
            }
        }
    }
    mutation removeFavorite($input: RemoveFavoriteInput!) {
        removeFavorite(input: $input) {
            success
        }
    }
`;

export const getAddFavoriteMutation = (type: FavoriteButtonProps['type']): DocumentNode => {
    switch (type) {
        case 'company':
            return AddFavoriteCompanyDocument;
        case 'activity':
            return AddFavoriteActivityDocument;
        case 'rentalUnit':
            return AddFavoriteRentalUnitDocument;
        default:
            throw new UnreachableCaseError(type);
    }
};

const FavoriteButtonInner: FC<React.PropsWithChildren<FavoriteButtonProps>> = ({
    type,
    id,
    isAuthorized,
    ActiveButtonComponent = ActiveFavoriteButton,
    InactiveButtonComponent = InactiveFavoriteButton,
    ...props
}) => {
    const { data } = useFavoriteButtonQuery({
        // should already have been fetched by the higher-level withAuth hoc
        fetchPolicy: 'cache-only',
    });
    const landingUrl = props.landingUrl ?? `${window?.location.origin + window?.location.pathname}#addToFavorites`;

    const isFavorite = useMemo(
        () =>
            data?.viewer?.favorites.some(favorite => {
                switch (type) {
                    case 'company':
                        return favorite.__typename === 'FavoriteCompany' && favorite.targetId === id;
                    case 'activity':
                        return favorite.__typename === 'FavoriteActivity' && favorite.targetId === id;
                    case 'rentalUnit':
                        return favorite.__typename === 'FavoriteRentalUnit' && favorite.targetId === id;
                    default:
                        throw new UnreachableCaseError(type);
                }
            }),
        [data?.viewer?.favorites, id, type]
    );
    const { open, onClose, onOpen } = useSesame();
    const favoriteId = useMemo(
        () => data?.viewer?.favorites.find(fav => fav.targetId === id)?.id,
        [data?.viewer?.favorites, id]
    );
    const { brandConfig } = useTenantContext();
    const client = useApolloClient();
    const [addFavorite, { loading: addFavoriteLoading }] = useMutation<
        AddFavoriteRentalUnitMutation,
        { input: AddFavoriteActivityInput | AddFavoriteCompanyInput | AddFavoriteRentalUnitInput }
    >(getAddFavoriteMutation(type), {
        errorPolicy: 'ignore',
    });
    const [removeFavorite, { loading: removeFavoriteLoading }] = useRemoveFavoriteMutation({
        errorPolicy: 'ignore',
    });
    const loading = addFavoriteLoading || removeFavoriteLoading;

    const onRemove = useCallback(async () => {
        try {
            await removeFavorite({
                variables: { input: { favoriteId: favoriteId! } },
            });
            client.writeQuery<FavoriteButtonQuery>({
                query: FavoriteButtonDocument,
                variables: {},
                data: {
                    __typename: 'Query',
                    viewer: {
                        __typename: 'Customer',
                        favorites: data!.viewer!.favorites.filter(fav => fav.id !== favoriteId),
                    },
                },
            });
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.error(ex);
            toast(
                <Toast title={<FormattedMessage defaultMessage="Favoriet verwijderen mislukt" />} variant="error">
                    <Body variant="small">
                        <FormattedMessage defaultMessage="Probeer het later opnieuw" />
                    </Body>
                </Toast>,
                { autoClose: 3000 }
            );
        }
    }, [client, data, favoriteId, removeFavorite]);

    const onAdd = useCallback(async () => {
        try {
            const result = await addFavorite({
                variables: (() => {
                    switch (type) {
                        case 'activity':
                            return {
                                input: {
                                    activityId: id,
                                },
                            };
                        case 'company':
                            return {
                                input: {
                                    companyId: id,
                                },
                            };
                        case 'rentalUnit':
                            return {
                                input: {
                                    rentalUnitId: id,
                                },
                            };
                        default:
                            throw new UnreachableCaseError(type);
                    }
                })(),
            });

            if (result.data?.result.favorite?.id) {
                // update cache ourselves, so we don't have to make a roundtrip to the server again
                client.writeQuery<FavoriteButtonQuery>({
                    query: FavoriteButtonDocument,
                    variables: {},
                    data: {
                        __typename: 'Query',
                        viewer: {
                            __typename: 'Customer',
                            favorites: [
                                ...data!.viewer!.favorites,
                                {
                                    __typename: (() => {
                                        switch (type) {
                                            case 'activity':
                                                return 'FavoriteActivity';
                                            case 'company':
                                                return 'FavoriteCompany';
                                            case 'rentalUnit':
                                                return 'FavoriteRentalUnit';
                                            default:
                                                throw new UnreachableCaseError(type);
                                        }
                                    })(),
                                    id: result.data.result.favorite.id,
                                    targetId: id,
                                },
                            ],
                        },
                    },
                });
            }
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.error(ex);
            toast(
                <Toast title={<FormattedMessage defaultMessage="Favoriet toevoegen mislukt" />} variant="error">
                    <Body variant="small">
                        <FormattedMessage defaultMessage="Probeer het later opnieuw" />
                    </Body>
                </Toast>,
                { autoClose: 3000 }
            );
        }
    }, [addFavorite, type, id, client, data]);

    const onClick = useCallback(() => {
        if (!isAuthorized) {
            onOpen();
        } else if (isFavorite) {
            onRemove();
        } else {
            onAdd();
        }
    }, [isAuthorized, isFavorite, onAdd, onOpen, onRemove]);
    const ButtonComponent = isFavorite ? ActiveButtonComponent : InactiveButtonComponent;

    const router = useRouter();
    useEffect(
        () => {
            if (router.query.addToFavorites && !isFavorite) {
                router.replace({ pathname: router.pathname, query: omit(router.query, 'addToFavorites') }, undefined, {
                    shallow: true,
                });
                onAdd();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const [isLogin, setIsLogin] = useState(false);
    const ModalContent = isLogin ? StyledLoginContent : StyledRegisterContent;

    return (
        <>
            <ButtonComponent onClick={onClick} submitting={loading} disabled={loading} />

            <Modal open={open} onClose={onClose} variant="regular">
                <ModalContent
                    onToggle={() => setIsLogin(login => !login)}
                    title={
                        isLogin ? (
                            <FormattedMessage defaultMessage="Mijn {island}" values={{ island: brandConfig.name }} />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Sla favorieten op voor later"
                                values={{ island: brandConfig.name }}
                            />
                        )
                    }
                    description={
                        isLogin ? (
                            <FormattedMessage defaultMessage="Bekijk uw reservering(en), doe een betaling, print uw voucher of geef een beoordeling op uw verblijf." />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Maak direct een Mijn {island} account aan. Zonder wachtwoord, gewoon met je e-mailadres."
                                values={{ island: brandConfig.name, br: <br /> }}
                            />
                        )
                    }
                    // helperText={
                    //     process.env.NEXT_PUBLIC_BRAND === 'texel' && (
                    //         <FormattedMessage defaultMessage="* Als cadeautje krijg je direct de PDF brochure Evenementen op Texel 2023" />
                    //     )
                    // }
                    landingUrl={landingUrl}
                    brandName={brandConfig.name}
                />
            </Modal>
        </>
    );
};

const FavoriteButtonOuter: FC<React.PropsWithChildren<FavoriteButtonProps & { loading: boolean }>> = ({
    loading,
    ...props
}) => {
    const SpinnerComponent = props.Spinner ?? Spinner;

    if (loading) {
        return <SpinnerComponent />;
    }

    return <FavoriteButtonInner {...props} />;
};

export const ActiveFavoriteButton: FC<React.PropsWithChildren<ButtonProps>> = props => (
    <Button variant="outline" {...props}>
        <ActiveHeart />
        <span className="gt-s">
            <FormattedMessage defaultMessage="Opgeslagen" />
        </span>
    </Button>
);

export const InactiveFavoriteButton: FC<React.PropsWithChildren<ButtonProps>> = props => (
    <Button variant="outline" {...props}>
        <InactiveHeart />
        <span className="gt-s">
            <FormattedMessage defaultMessage="Opslaan" />
        </span>
    </Button>
);

const Spinner = () => <InactiveFavoriteButton disabled style={{ cursor: 'progress' }} />;

const FavoriteButtonWithAuth = withAuth(FavoriteButtonOuter, {
    withLoadingProp: true,
    authQuery: FavoriteButtonDocument,
    onFail: () => 'ignore',
});

const FavoriteButton: FC<React.PropsWithChildren<FavoriteButtonProps>> = props => {
    const router = useRouter();
    useEffect(() => {
        (async () => {
            // fix url when we're getting here from a login email.
            // hacky, but no way around this without modifying how the backend interprets our landingUrl
            if (typeof window !== 'undefined' && window.location.hash.startsWith('#addToFavorites')) {
                await router.replace(
                    {
                        pathname: router.pathname,
                        query: {
                            ...router.query,
                            ...parse(window.location.hash.replace('#addToFavorites', '')),
                            addToFavorites: 1,
                        },
                    },
                    undefined,
                    {
                        shallow: true,
                    }
                );
                setShowSpinner(false);
            }
        })();
    }, [router]);

    const [showSpinner, setShowSpinner] = useState(
        typeof window !== 'undefined' && window.location.hash.startsWith('#addToFavorites')
    );
    const SpinnerComponent = props.Spinner ?? Spinner;

    return showSpinner ? <SpinnerComponent /> : <FavoriteButtonWithAuth {...props} />;
};

const StyledLoginContent = styled(LoginContent)`
    max-width: unset;
`;
const StyledRegisterContent = styled(RegisterContent)`
    max-width: unset;
`;

const StyledHeart = styled(Heart)`
    margin-left: -1.5rem;
    margin-right: -2.4rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-right: 0.7rem;
        margin-left: -1.2rem;
    }
`;

export const ActiveHeart = styled(StyledHeart)`
    color: ${({ theme }) => theme.colors.negative[50]};
`;

export const InactiveHeart = styled(StyledHeart)`
    color: rgba(0, 0, 0, 0.33);
`;
export default FavoriteButton;
