import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box } from '@rebass/grid';
import { gql } from '@apollo/client';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';
import messages from '../constants/messages';
import { useRegisterMutation } from '../generated/graphql';
import { logout } from '../utils/auth';
import Formed from './Formed';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import CheckEmailMessage from './CheckEmailMessage';
import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import Stack from './Stack';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { useRouter } from 'next/router';

/* eslint-disable no-underscore-dangle */

interface Values {
    email: string;
    newsletterOptIn: boolean;
}

const initialValues: Values = {
    email: '',
    newsletterOptIn: true,
};

export const query = gql`
    mutation Register($input: RegisterInput!) {
        register(input: $input) {
            customer {
                id
            }
        }
    }
`;

interface Props {
    title: ReactNode;
    description?: ReactNode;
    helperText?: ReactNode;
    landingUrl: string;
    brandName?: ReactNode;
    className?: string;
    onToggle?: () => void;
}

const RegisterContent: FC<React.PropsWithChildren<Props>> = ({
    title,
    description,
    helperText,
    landingUrl,
    brandName,
    className,
    ...props
}) => {
    const { formatMessage, locale } = useIntl();
    const [mutate, { loading, called, data }] = useRegisterMutation();
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                email: Yup.string()
                    .email(formatMessage(messages.emailError))
                    .required(formatMessage(messages.fieldError)),
            }),
        [formatMessage]
    );
    const handleSubmit = useCallback(
        async (values: Values) => {
            // remove current tokens from localStorage otherwise we could get a 401
            logout();

            // trigger newsletter event (only relevant for texel. needs to be streamlined for across all brands at some point...)
            if (values.newsletterOptIn) {
                window._sqzl = window._sqzl || [];

                window._sqzl.push({
                    event: 'MijnTexelOptin',
                    custom_frequentie: 'week',
                    custom_taal: locale,
                    email: values.email,
                    newsletter: 'yes',
                    marketing: 'no',
                    service: 'no',
                });
            }
            await mutate({
                variables: {
                    input: {
                        ...values,
                        agreedToTerms: true,
                        landingUrl,
                    },
                },
            });
        },
        [landingUrl, locale, mutate]
    );
    const router = useRouter();
    const onLogin =
        props.onToggle ??
        function onLogin() {
            router.push('/login');
        };

    return (
        <Content className={className}>
            <Formed<Values>
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                validationSchema={validationSchema}
                skipPrompt
            >
                {() => {
                    if (called && data?.register?.customer?.id) {
                        return (
                            <>
                                <CheckEmailMessage onTryAgain={() => router.push('/login')} brandName={brandName} />
                            </>
                        );
                    }
                    return (
                        <>
                            <Title>{title}</Title>
                            {description && <Description>{description}</Description>}

                            <Stack spacing={4}>
                                <Box id="email">
                                    <FieldSetLabelWrapper>
                                        <FieldSetLabel>
                                            <FormattedMessage defaultMessage="E-mailadres" />
                                        </FieldSetLabel>
                                    </FieldSetLabelWrapper>
                                    <EmailField
                                        data-cy="loginEmail"
                                        name="email"
                                        type="email"
                                        placeholder={formatMessage({ defaultMessage: 'voorbeeld@mail.com' })}
                                    />
                                </Box>

                                <CheckboxField name="newsletterOptIn" id="newsletterOptIn">
                                    <FormattedMessage defaultMessage="Ik ga akkoord met het ontvangen van nieuws en updates." />
                                </CheckboxField>
                            </Stack>

                            <LoginButton submitting={loading} size="large" type="submit">
                                <FormattedMessage defaultMessage="Account aanmaken" />
                            </LoginButton>

                            {helperText && <NeutralBody variant="tiny">{helperText}</NeutralBody>}
                            <CreateAccountWrapper>
                                <StyledBody>
                                    <FormattedMessage defaultMessage="Heb je al een account?" />
                                </StyledBody>
                                <StyledLink
                                    href="#"
                                    onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                        e.preventDefault();
                                        onLogin();
                                    }}
                                >
                                    <FormattedMessage defaultMessage="Inloggen" />
                                </StyledLink>
                            </CreateAccountWrapper>
                        </>
                    );
                }}
            </Formed>
        </Content>
    );
};

export default RegisterContent;

const Content = styled(Box)`
    max-width: 44.8rem;
`;

const LoginButton = styled(Button)`
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
    margin-top: 2.4rem;
`;

const NeutralBody = styled(Body)`
    color: ${({ theme }) => theme.colors.neutral['50']};
    text-align: center;
`;

const Description = styled(Body)`
    margin-top: ${({ theme }) => theme.spacing['30_Small']};
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
    padding-bottom: ${({ theme }) => theme.spacing['30_Small']};
`;

const EmailField = styled(TextInputField)`
    min-height: ${({ theme }) => theme.spacing['60_Large']};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        min-height: ${({ theme }) => theme.spacing['70_XLarge']};
    }

    ::placeholder {
        color: ${({ theme }) => theme.colors.neutral['40']};
    }
`;

const FieldSetLabelWrapper = styled.div`
    margin-bottom: 1.6rem;
`;

const FieldSetLabel = styled.label`
    ${({ theme }) => getFontCss(theme.fontSizes.body.small)};
    font-weight: 400;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.primary['80']};
`;

const CreateAccountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledBody = styled(Body)`
    ${({ theme }) => getFontCss(theme.fontSizes.body.small)};
    line-height: 1.6rem;
    text-align: center;
`;

const StyledLink = styled.a`
    margin-left: 0.4rem;
    font-weight: 700;
    ${({ theme }) => getFontCss(theme.fontSizes.body.small)};
    line-height: 2.4rem;
    color: #000000;
    text-underline-offset: 0.4rem;
    text-decoration-color: ${({ theme }) => theme.colors.neutral['20']};
    text-decoration-thickness: 0.2rem;
`;
